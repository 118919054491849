import React, { useState } from 'react';
import ColorTabs from '../components/Tab';
import Footer from '../components/footer';
import DrawerComponent from '../components/Drawer';

import { Helmet } from 'react-helmet';

const headerStyle = {
  position: 'fixed',
  top: 0,
  width: '100%',
  zIndex: 1000 // Ensure it's on top of other elements
}

const nextDivStyle = {
  marginTop: '70px' // Adjust based on your header height
}

const MainPage = () => {

  return (
    <div>
      <Helmet>
        <title>聖經中英對照</title>
        <link rel="canonical" href="https://www.zeoinjesus.com" />
      </Helmet>
      <div style={headerStyle} className="grid-container-for-title">
        <div className="grid-item"></div>
        <div className="grid-item"><h1>聖經中英對照</h1></div>
        <div className="grid-item"></div>
      </div>
      <div style={nextDivStyle}></div>
      <DrawerComponent />

      <ColorTabs/>
      <Footer />
    </div >
  );
};

export default MainPage;