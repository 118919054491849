import React, { useState } from 'react';
import axios from 'axios';
import InputDialog from './InputDialog';
const Footer = () => {
    const [clickCount, setClickCount] = useState(0);
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleClick = () => {
        setClickCount(prevCount => prevCount + 1);
        if (clickCount + 1 >= 12) {
            setDialogOpen(true);
        }
    };

    const handleClose = () => {
        setDialogOpen(false);
        setClickCount(0); // Reset click count after dialog is closed
    };

    const devModeFetch = async (password) => {
        try {

            try {
                const response = await axios.post('https://www.zeoinjesus.com/api/dev_mode/', {
                //const response = await axios.post('http://localhost:8000/api/dev_mode/', {
                    //const response = await axios.post('http://134.122.2.66:8000/api/dev_mode/', {
                    declaration: password,
                });

                
                localStorage.setItem('dev', true);
                

                console.log('dev_mode', response);

            } catch (error) {
                console.error('Error sending data:', error);
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }


    };


    const handleSubmit = (password) => {
        password = password.toLocaleLowerCase();
        console.log('Password entered:', password);

        devModeFetch(password);


        setDialogOpen(false);
        setClickCount(0); // Reset click count after password is submitted


    };


    return (

        <>
            <div onClick={handleClick} className="noselect" style={{
                display: "flex",
                padding: "5px 5px",
                overflowX: "auto",
                width: "100%",
                background: "#E0E0E0",
                boxSizing: "border-box" // <--- this line
            }}>
                <h6>網站聲名及免責</h6><br />
                <p style={{ fontSize: 13 }}>
                    網站作者們希望製作一個簡單易用，能對齊不同譯本的讀經工具<br /><br />1. 本網站只供個人用作學術及靈修使用，不得轉載任何內容<br /><br />
                    2. 本網站不能確保譯本的準確性及其版本：<br />
                    所有譯本均為其他網頁的免費資源，請自行往各大聖經公會購買聖經以確保經文的準確性<br /><br />
                    3. 本網站未曾收取任何奉獻，亦不會收取任何奉獻，更不會加入廣告，請各位奉獻支持各大聖經公會
                </p>
            </div>
            <InputDialog open={dialogOpen} onClose={() => handleClose()} onSubmit={handleSubmit} />
        </>

    );
};

export default Footer;