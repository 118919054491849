import React, { useState, useEffect } from 'react';
import new_testament from '../resources/new_testament.json';
import old_testament from '../resources/old_testament.json';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';




export default function InteractiveList({ flag }) {
    const [items, setItems] = React.useState([]);
    const [items2, setItems2] = React.useState([]);
    const [selectedBook, setSelectedBook] = useState(null);
    const [selectedChapter, setSelectedChapter] = useState(null);
    const navigate = useNavigate();

    const handleItemClick = (item, i) => {
        setSelectedBook(item);
        setSelectedChapter(i);
        //console.log('InteractiveList: Selected Book:', item.abbr);
        //console.log('InteractiveList: Selected Chapter:', i);

        var target = {...item, 'selectedChapter' : i};

        console.log('InteractiveList: target:', target);
 
        navigate('/display', { state:  target  });
    };


    useEffect(() => {
        setItems(new_testament);
    }, []);
    useEffect(() => {
        setItems2(old_testament);
    }, []);

    return (
        <div>
            {flag === 'old' ? (
                <Accordion >
                    {items2.map((item, index) => (
                        <Accordion.Item eventKey={index}>
                            <Accordion.Header>{item.name} {item.english}</Accordion.Header>
                            <Accordion.Body>
                                {
                                    [...Array(item.chapters)].map((e, i) =>
                                        <Button onClick={() => handleItemClick(item, i + 1)}
                                            style={{ padding: '10px 10px', margin: '5px' }}
                                            variant="outline-info" size="sm key={i}">
                                            {i + 1}
                                        </Button>
                                    )
                                }

                            </Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion>

            ) : (
                <Accordion >
                    {items.map((item, index) => (
                        <Accordion.Item eventKey={index}>
                            <Accordion.Header>{item.name} {item.english}</Accordion.Header>
                            <Accordion.Body>
                                {
                                    [...Array(item.chapters)].map((e, i) =>
                                        <Button onClick={() => handleItemClick(item, i + 1)}
                                            style={{ padding: '10px 10px', margin: '5px' }}
                                            variant="outline-info" size="sm key={i}">
                                            {i + 1}
                                        </Button>
                                    )
                                }

                            </Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion>
            )
            }
        </div >
    );

    // return (
    //     <div>
    //         {flag === 'old' ? (
    //             <ListGroup>
    //                 {items2.map((item, index) => (
    //                     <ListGroup.Item action key={index} onClick={() => handleItemClick(item)}>
    //                         <Card.Body>
    //                             <Card.Title style={{ textAlign: "center" }}>{item.name}</Card.Title>
    //                             <Card.Subtitle style={{ textAlign: "center" }} className="mb-2 ">{item.english}</Card.Subtitle>
    //                         </Card.Body>
    //                     </ListGroup.Item>
    //                 ))}
    //             </ListGroup>
    //         ) : (<ListGroup>
    //             {items.map((item, index) => (
    //                 <ListGroup.Item action key={index} onClick={() => handleItemClick(item)}>
    //                     <Card.Body>
    //                         <Card.Title style={{ textAlign: "center" }}>{item.name}</Card.Title>
    //                         <Card.Subtitle style={{ textAlign: "center" }} className="mb-2 ">{item.english}</Card.Subtitle>
    //                     </Card.Body>
    //                 </ListGroup.Item>
    //             ))}
    //         </ListGroup>)
    //         }
    //     </div >
    // );

}
