import React, { useState, useEffect } from 'react';
import { Drawer, Button, List, ListItem, ListItemText } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';

import TextField from '@mui/material/TextField';

import Box from '@mui/material/Box';

import new_testament from '../resources/new_testament.json';
import old_testament from '../resources/old_testament.json';


import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import Typography from '@mui/material/Typography';


import { useNavigate } from 'react-router-dom';


const DrawerComponent = () => {
    const [isDev, setIsDev] = useState(() => {
        return localStorage.getItem('dev') || false;
    });
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);

    const [newTest, setNewTest] = React.useState([]);
    const [oldTest, setOldTest] = React.useState([]);
    const [targetBooks, setTargetBooks] = React.useState([]);
    const [selectedBooks, setSelectedBooks] = React.useState('all');

    const [selectedValues, setSelectedValues] = useState(['cuv', 'nasb']);

    const [inputValue, setInputValue] = useState('');

    const toggleDrawer = (open) => (event) => {

        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setIsOpen(open);
    };


    useEffect(() => {
        setNewTest(new_testament);
    }, []);
    useEffect(() => {
        setOldTest(old_testament);
    }, []);
    useEffect(() => {
        var radioButtonItems = [];

        radioButtonItems.push({ "name": "全部", "abbr": "all", "short": "", "chapters": 1, "english": "All" });
        radioButtonItems.push({ "name": "舊約全書", "abbr": "old", "short": "", "chapters": 1, "english": "Old Testament" });
        radioButtonItems.push({ "name": "新約全書", "abbr": "new", "short": "", "chapters": 1, "english": "New Testament" });

        var fullList = radioButtonItems.concat(old_testament).concat(new_testament);
        //console.log('full list: ', fullList);
        setTargetBooks(fullList);
    }, []);

    const handleRadioGroupChange = (event) => {
        setSelectedBooks(event.target.value);
    };

    const handleSelection = (event, newValues) => {
        setSelectedValues(newValues);

        //console.log('handleSelection: ', newValues);
    };

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const getSelectedValues = () => {
        //console.log('Selected Values:', selectedValues);
        return selectedValues;
    };



    const handleSearch = () => (event) => {


        //console.log('handleSearch - event: ', event);
        console.log('handleSearch - getSelectedValues(): ', getSelectedValues());
        console.log('handleSearch - selectedBooks: ', selectedBooks);
        console.log('handleSearch - inputValue: ', inputValue);

        var combinedBooks = [];

        var targetBooks = [];

        if (selectedBooks === 'all') {

            combinedBooks = [...old_testament, ...new_testament];
            combinedBooks.forEach(item => {
                targetBooks.push(item.abbr);
            });
        } else if (selectedBooks === 'new') {

            combinedBooks = [...new_testament];
            combinedBooks.forEach(item => {
                targetBooks.push(item.abbr);
            });
        } else if (selectedBooks === 'old') {

            combinedBooks = [...old_testament];
            combinedBooks.forEach(item => {
                targetBooks.push(item.abbr);
            });
        } else {
            targetBooks.push(selectedBooks);
        }

        console.log('targetBooks', targetBooks)

        var target = { 'versions': getSelectedValues(), 'keywords': inputValue, 'books': targetBooks };

        navigate('/search', { state: target });
    };

    return (
        <div sx={{ width: '80%' }}>


            <Button startIcon={<SearchIcon />} onClick={toggleDrawer(true)}></Button>
            <Drawer anchor="left" open={isOpen} onClose={toggleDrawer(false)}>
                <Box
                    role="presentation"
                    onClick={(e) => e.stopPropagation()}
                    onKeyDown={(e) => e.stopPropagation()}
                >
                    <div style={{
                        padding: "20px 20px",
                    }}>

                        <h1>搜尋工具</h1>

                        <div>
                            <ToggleButtonGroup
                                value={selectedValues}
                                sx={{ flexWrap: "wrap" }}
                                onChange={handleSelection}
                                aria-label="multi select toggle button"
                            >
                                <ToggleButton value="cuv" aria-label="option 1">
                                    和合本
                                </ToggleButton>
                                <ToggleButton value="kjv" aria-label="option 2">
                                    KJV
                                </ToggleButton>
                                <ToggleButton value="lzz" aria-label="option 3">
                                    呂振中
                                </ToggleButton>
                                <ToggleButton value="nasb" aria-label="option 4">
                                    NASB
                                </ToggleButton>
                                {isDev &&
                                    <ToggleButton value="ncv" aria-label="option 5">
                                        新譯本
                                    </ToggleButton>
                                }
                                <ToggleButton value="niv" aria-label="option 6">
                                    NIV
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </div>

                        <div><br /></div>



                        <div>
                            <TextField
                                id="outlined-multiline-static"
                                label="搜尋關鍵字"
                                multiline
                                value={inputValue}
                                onChange={handleInputChange}
                                rows={4}
                                sx={{ width: '100%' }}
                                defaultValue="搜尋關鍵字"
                            />
                        </div>

                        <div style={{
                            padding: "2.5px 0px",
                        }}>
                            <Button variant="outlined" onClick={handleSearch()} startIcon={<SearchIcon />} >開始搜尋</Button>
                        </div>

                        <div><br /></div>

                        <FormControl component="fieldset">
                            <FormLabel component="legend">設定搜尋範圍：</FormLabel>
                            <RadioGroup aria-label="options" name="options" value={selectedBooks}
                                onChange={handleRadioGroupChange}>

                                {
                                    targetBooks.map((e, i) =>

                                        <FormControlLabel
                                            key={i}
                                            defaultChecked={e.abbr === 'all'}
                                            value={e.abbr}
                                            control={<Radio />}
                                            label={
                                                <Typography sx={{ fontSize: 13 }}>
                                                    {e.name + " " + e.english}
                                                </Typography>
                                            }
                                        />
                                    )
                                }


                            </RadioGroup>
                        </FormControl>
                    </div>
                </Box>
            </Drawer>
        </div>
    );
};

export default DrawerComponent;
